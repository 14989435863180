import { graphql, PageProps } from 'gatsby';

import Flickity from 'react-flickity-component';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import parse from 'html-react-parser';

type StudioPageProps = PageProps<{}>;

export const queryStudio = graphql`
  fragment Slide1Images on STRAPI__MEDIA {
    localFile {
      publicURL
    }
  }

  fragment Slide2Images on STRAPI__MEDIA {
    localFile {
      publicURL
    }
  }

  query StudioPage {
    strapiStudio {
      content {
        data {
          content
        }
      }
      slider1 {
        ...Slide1Images
      }
      slider2 {
        ...Slide2Images
      }
    }
  }
`;

const StudioPage = ({ data }: StudioPageProps) => {
  const flickityOptions = {
    prevNextButtons: false,
    pageDots: false,
  };

  return (
    <Layout>
      <Seo title={'Home'} />
      <div className='page-studio'>
        <div className='content'>
          <div className='images'>
            <Flickity
              className={'carousel'}
              elementType={'div'}
              options={flickityOptions}
            >
              {data.strapiStudio.slider1.map((image) => (
                <img src={image.localFile.publicURL} alt='' />
              ))}
            </Flickity>

            <Flickity
              className={'carousel'}
              elementType={'div'}
              options={flickityOptions}
            >
              {data.strapiStudio.slider2.map((image) => (
                <img src={image.localFile.publicURL} alt='' />
              ))}
            </Flickity>
          </div>
          <div className='text'>
            {parse(data.strapiStudio.content.data.content)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StudioPage;
